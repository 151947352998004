'use client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslations } from 'next-intl';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

const ErrorPage = ({ error, reset }: Props) => {
  const t = useTranslations('ErrorPage');

  useEffect(() => {
    datadogLogs.logger.error('ErrorPage', error);
  }, [error]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h3" textAlign="center">
          {t('title')}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }} width="100%">
          <Button variant="contained" onClick={reset}>
            {t('click-to-reload-this-page')}
          </Button>
        </Box>
        <Typography variant="body1" textAlign="center">
          {t('support')}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ErrorPage;
